import React, { useState, useEffect, Fragment } from "react";
import apiRequest from "../../Api";
import BasicTable from "../../components/ListingTable";
import ReactToast from "../../components/Toast";
import SearchBar from "../../components/SearchBar";

function LedgerListing(props) {

  const [ledgerList, setLedgerList] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
 

  useEffect(() => {
    if (isLoading) {
      CallAPiList();
    }
  }, [isLoading]); // Updated dependency array to contain isLoading only

  const onSearch = (event) => {
    let searchterm = event.target.value;

    if (searchterm) {
      const newItems = ledgerList.filter((row, index) => {
        return (
          row.invoice_no.toLowerCase().includes(searchterm.toLowerCase()) ||
          row.order_date.toLowerCase().includes(searchterm.toLowerCase()) ||
          row.net_amount.toLowerCase().includes(searchterm.toLowerCase())
        );
      });
      setFilterData(newItems);
    } else {
      setFilterData(ledgerList);
    }
  };

  let headerData = [
  
    {
      label: "Date",
      render: (data) => data.date,
      className: "text-left",
    },
    {
      label: "Account",
      render: (data) => data.acname,
      className: "text-center",
    },
    {
      label: "Desc",
      render: (data) => data.remarks,
      className: "text-left",
    },
    {
      label: "Debit Amount",
      render: (data) => data.debit,
      className: "text-right",
    },
    {
      label: "Credit Amount",
      render: (data) => data.credit,
      className: "text-right",
    },
    {
      label: "Balance",
      render: (data) => data.balance,
      className: "text-right",
    },
    {
      label: "DC",
      render: (data) => data.dc,
      className: "text-right",
    },
    {
      label: "Chq/Ref. No",
      render: (data) => data.vCheque,
      className: "text-right",
    },
    {
      label: "Chq/Ref. Dt.",
      render: (data) => data.date,
      className: "text-right",
    },
    {
      label: "Recon. Dt.",
      render: (data) => data.date,
      className: "text-right",
    },
    {
      label: "Recon. Status",
      render: (data) => data.ePaymentStatus,
      className: "text-right",
    },
  ];
  
  const CallAPiList = async () => {
    try {
      const url = process.env.REACT_APP_API_BASE;
      
      let apiSetting = {
        method: "POST",
        // Include your API parameters here
        apiParams : {
            partypkid : 15
        }
      };
      
      let listingData = await apiRequest(
        `${url}OrderPanelApi/ledger`,
        apiSetting
      );
     
      if (listingData.data && listingData.data.success == 1) { // Fixed comparison
       
         setLedgerList(listingData.data.data.data);
         setFilterData(listingData.data.data.data);
      } else {
        throw new Error(listingData.data.message);
      }
    } catch (error) {
      ReactToast({ type: "error", message: error.message });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Fragment>
       <SearchBar onSearch={onSearch} /> 
      <BasicTable headers={headerData} data={filterData} />
    </Fragment>
  );
}

export default LedgerListing;
